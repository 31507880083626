/*must be transparent*/
#siteContainer,
#siteContainer #siteContainer.container-fluid {
  background: transparent;
}
#yieldContent {
  .teamPage &,
  .sportsPage & {
    background: #ffffff;
  }
}
#panelTwo {
  padding-left: 0px;
  padding-right: 0px;
}
/*container spacing*/
#siteContainer #yieldContent.row,
#yieldContent {
    margin: 30px 0;
  .teamPage &,
  .sportsPage &
  .sportsHome & {
    background: white;
  }
}
#siteContainer, #siteContainer #siteContainer.container-fluid {
  padding: 0;
}
/* aggregators & layout containers in tab element */
.yieldPageContent .pageElement .item,
.yieldPageContent .pageElement .layoutContainer .column,
.eventAggregatorElement .vevent.odd, .eventAggregatorElement .vevent.even {
    background: #f1f1f1;
}
.yieldPageContent .pageElement .item,
.eventAggregatorElement .vevent {
   padding-left:15px;
   padding-right:15px;
}

/*container background*/
#yieldContent {
  background-color: white;
}

