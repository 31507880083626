// most of this just negates existing sitebuilder css
.sponsor-slider {
  display: none; //override this puppy when functions fire
  &.flexslider {
    line-height: initial;
    border: none;
    border-radius: 0;
    width: calc(100% + 30px);
    left: -15px;
    
    .page-manager-visible & {
    width: calc(100vw - (60px));
    left:calc(-1*((100vw - (60px) - 100%) / 2);
    }
    width: 100vw;
    left:calc(-1*((100vw) - 100%) / 2);
    
  }
  & .column {
    position: initial;
    min-height: initial;
    padding-left: 0px;
    padding-right: 0px;
    float:none;
    box-sizing: content-box;
    .pageEl:before {
      content:none;
    }
  }
  & .pageElement.heroPhotoElement {
    text-align: center;
    display: flex;
    float: none;
    margin: initial;
    width: initial;
    clear: initial;
    height: 150px; /*new*/
    & > a,
    & > img {
      display: initial;
      border: none;
      margin: auto;
      height: auto;
      width: auto;
      max-height: calc(100% - 50px);  /*new*/
      max-width: calc(100% - 50px);   /*new*/
    }
    > a img {
      height: initial;
      width: initial;
      max-height: calc(100% - 50px);
      max-width: calc(100% - 50px);
      border: 0;
    }
  }
  &[data-oversizedslider="true"] {
    .column {
      text-align: center;
      width: initial !important;
      justify-content: center;
      display: flex;
    }
    .flex-direction-nav {display:none;}
  }
}
// border styles

.sponsor-slider {
  .pageEl {
    border: 1px solid #cacaca;
    border-left-width: 0px;
    box-sizing: content-box;
    &:last-of-type {
      border-right-width: 0px;
      width: 300px !important;
    }
  }
  .flex-direction-nav a {
    border: 1px solid #cacaca;
  }
  &[data-oversizedslider="true"] {
    .pageEl{
      &:first-of-type {
        border-left: 1px solid #cacaca;
      }
      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}

// end border styles
// background

//flexslider CSS defaults to a white background so to negate that remove this if statement & keep the sponsorSlider.backgroundColor object as inherit
.sponsor-slider.flexslider {
  background-color: white;
}



// end background
// arrow animation style

.sponsor-slider.flexslider .flex-direction-nav .flex-prev,
.sponsor-slider.flexslider .flex-direction-nav .flex-next,
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-prev,
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-next {
    opacity: 1;
}
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-prev {
    left: 0px;
}
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-next {
    right: 0px;
}
.sponsor-slider.flexslider {
    margin: 0px;
}
.sponsor-slider .flex-viewport {
    width: calc(100% - 100px);
    margin: 0px auto;
    position: relative;
}

// end arrow animation style
// arrow styles

.sponsor-slider .flex-direction-nav a {
    height: 100%;
    top: 0;
    
    margin: 0;
}
.sponsor-slider .flex-direction-nav a.flex-next {
  right: 0px;
}
.sponsor-slider .flex-direction-nav a.flex-prev {
  left: 0px;
}
.sponsor-slider .flex-direction-nav a:before {
  height: 100%;
  width: 100%;
  line-height: 150px;
  text-align: center;
}

// end rectangle styles
// arrow width

.sponsor-slider .flex-direction-nav a {
  width: 50px;
}

// end arrow width
// arrow styles
// this is wrapping ths liquid conditonal formatting here b/c the settings we want to change are all within one CSS selector chain
.sponsor-slider .flex-direction-nav a:before {

  font-family: 'FontAwesome';


  font-size: 40px;


  color: #cacaca;


  text-shadow: none;


  content: '\f104';

}
.sponsor-slider .flex-direction-nav a.flex-next:before {

  content: '\f105';

}
